import supabase from "../services/supabase";
import React, { useEffect, useState, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AdminInterface } from "./AdminInterface";
import Modal from "react-bootstrap/Modal";
import logo from './Sitruna_Logo_white.png';
import "../App.css";

const Home = ({ user }) => {
  // const [idToken, setIdToken] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState("Dashboard");
  const [customLinks, setCustomLinks] = useState([]);
  const [driveUrl, setDriveUrl] = useState(null);
  const [parameter, setParameter] = useState(null); // New state for parameter
  const [isReady, setIsReady] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);

  const fetchUserData = useCallback(async () => {

    const { data, error } = await supabase
      .from('sitruna_parameters')
      .select('*')
      .eq('email', user.email);

    if (error) {
      console.error('Error fetching data from Supabase:', error);
      return;
    }

    // if (user.email === "amz@sitruna.com" || user.email === "admin@nikura.com" || user.email === "demo-nikura@lookermetrics.com" || user.email === "saulbeechmore@gmail.com") {
    //   const token = await user.getIdToken(true);
    //   setIdToken(token);
    // }

    const userData = data[0]; // Assuming only one record per user email
    setCustomLinks(userData.custom_links || []);
    setDriveUrl(userData.drive_url || "");
    setParameter(userData.parameter || ""); // Store parameter in state

    setIsReady(true);
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user, fetchUserData]);

  const customizeIframe = useCallback(() => {
    const iframe = document.querySelector('#portal');
    // const tempSrc = `https://lookerstudio.google.com/embed/reporting/d0b989b0-bbec-4fd5-905e-c8fda8502f7c/page/p_xw9lmmbz6c?params=%7B"ds344.company":"${parameter}","ds730.company":"${parameter}","ds78.company":"${parameter}","ds112.company":"${parameter}","ds475.company":"${parameter}","ds232.company":"${parameter}","ds287.company":"${parameter}","ds313.company":"${parameter}","ds456.company":"${parameter}","ds401.company":"${parameter}","ds402.company":"${parameter}","ds390.company":"${parameter}","ds438.company":"${parameter}","ds536.company":"${parameter}","ds461.company":"${parameter}","ds474.company":"${parameter}","ds504.company":"${parameter}%7D`;
    const tempSrc = `https://lookerstudio.google.com/embed/reporting/d0b989b0-bbec-4fd5-905e-c8fda8502f7c/page/p_xw9lmmbz6c?params=%7B
    "ds344.company":"${parameter}",
    "ds730.company":"${parameter}",
    "ds78.company":"${parameter}",
    "ds112.company":"${parameter}",
    "ds475.company":"${parameter}",
    "ds232.company":"${parameter}",
    "ds287.company":"${parameter}",
    "ds313.company":"${parameter}",
    "ds456.company":"${parameter}",
    "ds401.company":"${parameter}",
    "ds402.company":"${parameter}",
    "ds390.company":"${parameter}",
    "ds438.company":"${parameter}",
    "ds536.company":"${parameter}",
    "ds461.company":"${parameter}",
    "ds474.company":"${parameter}",
    "ds504.company":"${parameter}"%7D`;
    iframe.setAttribute('height', 2300);
    iframe.setAttribute('src', tempSrc);
  }, [parameter]);

  const customizeIframe2 = useCallback((url) => {
    const iframe = document.querySelector('#portal');
    iframe.setAttribute('src', url);
    iframe.style.height = window.innerHeight - 110 + 'px';
  }, []);

  useEffect(() => {
    if (isReady) {
      customizeIframe();
    }
  }, [isReady, customizeIframe]);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error signing out:', error.message);
  };

  const renderNavigationLinks = () => {
    if (!isReady || (!driveUrl && customLinks.length === 0)) return null;

    return (
      <div className="col" style={{ margin: "1%", display: 'flex', justifyContent: 'center' }}>
        <Card.Body style={{ paddingRight: "0px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <div className="navigation-links">
            <span className={`nav-link ${selectedDashboard === 'Dashboard' ? 'active' : ''}`} onClick={() => { setSelectedDashboard('Dashboard'); customizeIframe(); }}>
              Dashboard
              {selectedDashboard === 'Dashboard' && <span className="underline"></span>}
            </span>
            {driveUrl && (
              <span className={`nav-link ${selectedDashboard === 'Google Drive' ? 'active' : ''}`} onClick={() => { setSelectedDashboard('Google Drive'); customizeIframe2(driveUrl); }}>
                Google Drive
                {selectedDashboard === 'Google Drive' && <span className="underline"></span>}
              </span>
            )}
            {customLinks.map((link, index) => (
              <span key={index} className={`nav-link ${selectedDashboard === link.name ? 'active' : ''}`} onClick={() => { setSelectedDashboard(link.name); customizeIframe2(link.url); }}>
                {link.name}
                {selectedDashboard === link.name && <span className="underline"></span>}
              </span>
            ))}
          </div>
        </Card.Body>
      </div>
    );
  };

  return (
    <div className="container-fluid" style={{ overflowX: "hidden", marginTop: "0%", marginBottom: "0.5%", paddingLeft: "0", paddingRight: "0" }}>
      <div className="row custom-row" style={{ backgroundColor: "#2D3647", borderColor: "#2D3647" }}>
        <div className="col" style={{ margin: "1%", marginLeft: "0.25%" }}>
          <img src={logo} width={"160px"} style={{ float: "left", marginRight: "24px" }} alt="Logo" />
        </div>
        {renderNavigationLinks()}
        <div className="col" style={{ margin: "1%", marginRight: "0%", paddingTop: "16px" }}>
          <Card.Body style={{ paddingRight: "0px", textAlign: "end", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {/* {(user.email === "amz@sitruna.com" || user.email === "admin@nikura.com" || user.email === "demo-nikura@lookermetrics.com") && (
              <Button className="me-md-2" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#3BA37F', borderColor: '#3BA37F', color: 'white' }} size="sm" onClick={() => window.open(`https://chat-widget.text2bi.com/?token=${idToken}`, '_blank')}>
                <i className="bi bi-bar-chart-line-fill"></i>
              </Button>
            )} */}
            <Button className="me-md-2 refresh-button" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#17b5c7', borderColor: '#17b5c7', color: 'white', padding: '2px 6px' }} size="sm" onClick={() => customizeIframe()}>
              <i className="bi bi-arrow-repeat" style={{ fontSize: '18px', fontWeight: 'bold' }}></i>
            </Button>
            {user && user.email === "amz@sitruna.com" && (
              <Button 
                className="me-md-2" 
                style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#f9c602', borderColor: '#f9c602', color: '#2d3647', padding: '2px 6px', display: 'flex', alignItems: 'center' }} 
                size="sm" 
                onClick={() => setShowAdminModal(true)}
              >
                <i className="bi bi-person-gear" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
                <span style={{ lineHeight: '18px' }}>Admin</span>
              </Button>
            )}
            <Button className="signout-button" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#FFFFFF', borderColor: '#FFFFFF', color: '#2D3647', padding: '2px 6px', display: 'flex', alignItems: 'center' }} size="sm" onClick={handleSignOut}>
              <i className="bi bi-box-arrow-right" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
              <span style={{ lineHeight: '18px' }}>Sign Out</span>
            </Button>
          </Card.Body>
        </div>
      </div>
      <div className="row">
        <iframe title="Dashboard Portal" id="portal" width="100%" height="2220"></iframe>
      </div>

      {/* Admin Modal */}
      <Modal show={showAdminModal} onHide={() => setShowAdminModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Manage Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminInterface />
        </Modal.Body>
      </Modal>
      {/* {(user.email === "amz@sitruna.com" || user.email === "admin@nikura.com" || user.email === "demo-nikura@lookermetrics.com" || user.email === "saulbeechmore@gmail.com") && (
        <>
          <Modal show={showChat} onHide={() => setShowChat(false)}>
            <Modal.Dialog style={{ position: 'fixed', bottom: '0px', right: '20px', maxWidth: '800px', borderRadius: '5px' }}>
              <Modal.Header closeButton>
                <Button onClick={() => window.open(`https://chat-widget.text2bi.com/?token=${idToken}`, '_blank')} style={{ marginRight: 'auto', backgroundColor: '#f8f9fa', borderColor: '#f8f9fa', color: '#343a40' }}>
                  <i className="bi bi-box-arrow-up-right"></i>
                </Button>
              </Modal.Header>
              <Modal.Body style={{ height: '600px', width: '450px', padding: 0, borderRadius: '5px' }}>
                <iframe title="Chat Portal" style={{ width: "100%", height: "100%" }} src={`https://chat-widget.text2bi.com/?token=${idToken}`}></iframe>
              </Modal.Body>
            </Modal.Dialog>
          </Modal>
          <Button onClick={() => setShowChat(true)} style={{ backgroundColor: '#3BA37F', borderColor: '#3BA37F', color: 'white', borderRadius: '50%', width: '60px', height: '60px', position: 'fixed', bottom: '20px', right: '20px', fontSize: '24px' }}>
            <i className="bi bi-chat-dots"></i>
          </Button>
        </>
      )} */}
      <div className="container" style={{ marginTop: "1.5%" }}>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-6 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <span className="mb-3 mb-md-0 text-muted">&copy; 2024 Sitruna Consulting Limited</span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
