import { useState, useEffect } from 'react';
import Login from './components/LoginPage';
import Home from './components/HomePage';
import supabase from './services/supabase'; // Import the Supabase client
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user || null);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
    });

    // Clean up the subscription on unmount
    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      } else {
        console.error('Unable to unsubscribe, authListener.unsubscribe is not a function');
      }
    };
  }, []);

  return (
    <div className="app">
      {user ? <Home user={user} /> : <Login />}
    </div>
  );
}

export default App;
