import React, { useState } from "react";
import supabase from "../services/supabase";
import "../App.css";
import logo from "./Sitruna_Logo_no_bg_white_i.png";

// Import the lemon images if they're separate files
import lemon1 from "./lemon1.png"; // Replace with the path to your image
import lemon2 from "./lemon2.png"; // Replace with the path to your image
import lemon3 from "./lemon3.png"; // Replace with the path to your image

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const handleLogin = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: loginEmail,
      password: loginPassword
    });
    if (error) console.error('Error logging in:', error.message);
  };

  return (
    <div className="login-wrapper">
      <img src={lemon1} alt="Lemon" className="lemon lemon-1" />
      <img src={lemon2} alt="Lemon" className="lemon lemon-2" />
      <img src={lemon3} alt="Lemon" className="lemon lemon-3" />
      <div className="login-container">
        <div className="login-logo-container">
          <img src={logo} alt="Sitruna Logo" className="login-logo" />
        </div>
        <h2 className="login-title">Sign in to your account</h2>
        <div className="login-form-container">
          <div className="login-form-card">
            <input
              id="email"
              type="email"
              className="login-input"
              placeholder="Email address"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
            <input
              id="password"
              type="password"
              className="login-input"
              placeholder="Password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
          </div>
          <button className="login-button" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
      <div className="login-footer">
        &copy; 2024 Sitruna Consulting Limited
      </div>
    </div>
  );
};

export default Login;
